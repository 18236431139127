<template>
  <header id="headers">
    <div
      class="opacity-div"
      v-if="navIsShow"
      @click="navIsShow = !navIsShow"
    ></div>
    <div class="header">
      <span class="logo" @click="gopage('/app', true)">
        <img src="../../../assets/images_app/logo.png" alt />
      </span>
      <span class="nav" @click="navIsShow = !navIsShow">
        <span></span>
        <span></span>
        <span></span>
      </span>

      <div class="navbar" :class="[navIsShow ? 'active' : '']">
        <span
          :class="[activeItem == '0' && 'active']"
          @click="
            activeItem = '0';
            gopage('/app', false);
          "
          >首页</span
        >
        <span
          :class="[activeItem == '1' && 'active']"
          @click="
            activeItem = '1';
            gopage('/Example', false);
          "
          >案例</span
        >
        <span
          :class="[activeItem == '2' && 'active']"
          @click="
            activeItem = '2';
            gopage('/Contact', false);
          "
          >联系我们</span
        >
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      activeItem: 0,
      navIsShow: false,
    };
  },
  methods: {
    gopage(path, isLogo) {
      isLogo ? (this.navIsShow = false) : (this.navIsShow = !this.navIsShow);
      this.$route.path != path && this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  display: flex;
  height: 44px;
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
  background: $bgColor;

  .logo {
    display: flex;
    cursor: pointer;
    justify-items: center;
    img {
      height: 26px;
    }
  }
  .nav {
    width: 24px;
    cursor: pointer;

    & > span {
      display: block;
      height: 3px;
      border-radius: 5px;
      background: #fff;
      & + span {
        margin-top: 6px;
      }
    }
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    background: $bgColor;
    border-top: 1px solid lighten($bgColor, 15%);
    transform: translateY(-50px) scaleY(0.3);
    transition: opacity 0.2s, transform 0.3s, z-index 0.1s 0.1s;
    &.active {
      z-index: 99;
      opacity: 1;
      transform: translateY(0) scaleY(1);
    }
    & > span {
      display: block;
      padding: 0 30px;
      line-height: 44px;
      color: #999999;
      background-color: #444444;
      border-bottom: 1px solid #5e5e5e;
      font-size: 14px;
      &:hover,
      &:focus,
      &.active {
        color: $red;
        background-color: #333333;
        border-bottom-color: $red;
      }
    }
  }

  @media (min-width: 768px) {
    height: 88px;
    padding: 0 30px;
    .logo {
      img {
        height: 52px;
      }
    }
    .nav {
      width: 40px;
      & > span {
        height: 4px;
        & + span {
          margin-top: 11px;
        }
      }
    }

    .navbar {
      transform: translateY(-100px) scaleY(0.3);
      & > span {
        padding: 0 60px;
        line-height: 88px;
        font-size: 28px;
      }
    }
  }
}

.opacity-div {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 19;
}
</style>