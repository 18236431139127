<template>
  <div class="cont" ref="homeCont">
    <headers></headers>
    <router-view></router-view>
  </div>
</template>

<script>
import { headers } from '@/components/app/headers';

export default {
  components: { headers }
};
</script>

<style lang="scss" scoped>
.cont {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
